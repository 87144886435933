import type { Api } from '@rialtic/api'
import type { FetchOptions } from 'ofetch'
import { ofetch } from 'ofetch'
import { acceptHMRUpdate, defineStore } from 'pinia'

const sortByName = (a: Pick<Api.Policy, 'name'>, b: Pick<Api.Policy, 'name'>) =>
  a.name?.localeCompare(b?.name)

export const useEditor = defineStore('main', {
  state: () => {
    return {
      pinnedPolicyIds: [] as string[],
      recentPolicyIds: [] as string[],
      policies: {} as Record<string, Api.Policy>,
    }
  },

  getters: {
    allPolicies: (state) => Object.values(state.policies),

    pinnedPolicyIndex: (state) => (policyId: string) =>
      state.pinnedPolicyIds.findIndex((id) => id === policyId),

    pinnedPolicies: (state) =>
      state.pinnedPolicyIds
        .map((id) => {
          const { name, latest_review_status } = state.policies[id] || {}
          return {
            id,
            name,
            latest_review_status,
          }
        })
        .sort(sortByName),

    policyIsPinned(): (policyId: string) => boolean {
      return (policyId: string) => this.pinnedPolicyIndex(policyId) >= 0
    },

    recentPolicies: (state) =>
      state.recentPolicyIds.map((id) => {
        const { name, latest_review_status } = state.policies[id] || {}
        return {
          id,
          name,
          latest_review_status,
        }
      }),
  },

  actions: {
    async $apiFetch<T>(
      url: string,
      opts?: FetchOptions<'json'>,
      bareApi = false,
    ) {
      const config = useRuntimeConfig().public

      const { $auth0, $auth0Ready } = useNuxtApp()
      const { getAccessTokenSilently } = $auth0()

      await $auth0Ready()

      const token = await getAccessTokenSilently({
        cacheMode: 'cache-only',
      })

      return ofetch<T>(url, {
        ...opts,
        baseURL: `${config.WORKERS_API_BASE_URL}${
          bareApi ? '' : '/policy-manager'
        }`,
        headers: {
          ...opts?.headers,
          Authorization: `Bearer ${token}`,
          'auth-provider': 'auth0',
          ...makeTracingHeaders(),
        },
      })
    },

    async getPolicies() {
      const res = await this.$apiFetch<Api.Policy[]>('/policy')

      this.setPolicies(res)

      return res
    },

    async getPolicy(id: string) {
      const res = await this.$apiFetch<Api.Policy>(
        `/policy/${id}`,
        undefined,
        true,
      )

      this.setPolicies([res])

      return res
    },

    pushToRecentPolicy(policyId: string) {
      const recentPolicies = [
        policyId,
        ...this.recentPolicyIds.filter((id) => id !== policyId),
      ].slice(0, 10)

      this.recentPolicyIds = recentPolicies
    },

    async setPolicies(policies: Api.Policy[]) {
      const { workerFn } = useWebWorkerFn(
        (data) => {
          const { policies, currentState } = JSON.parse(data)
          return policies.reduce((state, policy) => {
            state[policy.id] = policy
            return state
          }, currentState)
        },
        {
          timeout: 50000,
        },
      )

      const res = await workerFn(
        JSON.stringify({ policies, currentState: this.policies }),
      )

      this.policies = res
    },

    togglePolicyPin(policyId: string) {
      const index = this.pinnedPolicyIndex(policyId)
      if (index < 0) {
        this.pinnedPolicyIds.push(policyId)
      } else {
        this.pinnedPolicyIds.splice(index, 1)
      }
    },
  },
  // persist: {
  //   storage: persistedState.localStorage,
  // },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useEditor, import.meta.hot))
